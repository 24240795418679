// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconPencilAlt from "@cimpress-technology/react-streamline-icons/lib/IconPencilAlt";
import { useState } from "react";
import { editPencilStyle } from "../../styles/icons-configs";
import { ToolTipComp } from "../Hocs/toolTip";
import { OverWriteModal } from "./overWriteModal";
import { industryConfidenceScore } from "../../utils/commonUtility";

export function OverWriteComp(props: {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	field: string;
	value: string;
	identifier: string;
	identifierId: string;
	overwritten: string;
	rowIndex: number[];
}) {
	const defaultMessage = "Click to override value";
	const disabledMessage = "If Industry manually overridden; confidence set to 100";
	const [isOverWrite, setIsOverWrite] = useState<boolean>(false);
	const [y, setY] = useState(0);
	const [totalHeight, setTotalHeight] = useState(0);

	const isClickDisabled = props.field === industryConfidenceScore;
	const editIcon = (
		<IconPencilAlt
			weight={editPencilStyle.weight}
			color={isClickDisabled ? "grey" : editPencilStyle.color}
			size={editPencilStyle.size}
			onClick={(e: any) => {
				if (!isClickDisabled) {
					setY(e.clientY);
					setTotalHeight(e.view.innerHeight);
					setIsOverWrite(!isOverWrite);
				}
			}}
		/>
	);

	return (
		<>
			<div className={props.overwritten === "true" ? "pencil" : "hide-pencil"}
				data-testid={props.overwritten === "true" ? "pencil" : "hide-pencil"}
				id={props.overwritten === "true" ? `pencil-${props.field}` : `hide-pencil-${props.field}`}>
				{ToolTipComp({ 
					children: editIcon, 
					message: isClickDisabled ? disabledMessage : defaultMessage 
				})}
					
			</div>
			{isOverWrite && (
				<OverWriteModal
					y={y}
					rowIndex={props.rowIndex}
					totalHeight={totalHeight}
					setLoading={props.setLoading}
					field={props.field}
					value={props.value}
					identifierId={props.identifierId}
					identifier={props.identifier}
					isOpen={setIsOverWrite}
				/>
			)}
		</>
	);
}
