export interface IRow{
    [key:string]:string
}
export const getIdentifierIds=(allRows:IRow[][])=>{
    const ids = allRows.map((row: IRow[]) => {
    let id = "";
    row.forEach((cell: IRow) => {
      if ("ENRICHMENT_CORRELATION_ID" in cell) {
        id = cell["ENRICHMENT_CORRELATION_ID"];
      }
    });
    return id;
  });
  return ids
}

export const sectorCodeADescription = "Landbouw, bosbouw en visserij";
export const inProgress = "inProgress";
export const completed = "completed";
export const processing = "processing";
export const emailOrCompany = "emailOrCompany";
export const evoPortalRoute = "evo-portal";
export const detailedViewRowCount = 1;
export const evoOverrideConfidenceScore = '100'
export const industryConfidenceScore = "INDUSTRY_CONFIDENCE_SCORE";
export const no_user_data_count = 0;
export const tenants = ["test","druck","BAS","boxup","exagroup","tradeprint","nationalpen","WMD","easyflyer","printdeal","vistaprint","pixart"];
export const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
export const validKeys = [
  "CUSTOMER_NUMBER",
  "CUSTOMER_ID",
  "COMPANY_NAME",
  "EMAIL",
  "FIRST_NAME",
  "LAST_NAME",
  "STREET1",
  "STREET2",
  "CITY",
  "POSTCODE",
  "COUNTRY_CODE",
  "NATIONAL_ID",
  "WEBSITE",
  "PHONE_NUMBER"
];
export const timeForEachPoll = 5000;
export const NotShowingLoadingTime = 2000;
export const waitTimeBeforeProgressData = 1000;
