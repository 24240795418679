import { Button, Card, Modal, Snackbar } from "@cimpress/react-components/";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./BulkEnrich.css";
import UploadIcon from "../../assets/upload-icon.svg";
import CSVIcon from "../../assets/csv-icon.svg";
import DeleteIcon from "../../assets/delete-icon.svg";
import { formats } from "../../config";
import { setSnackBar } from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { countries } from "country-data";
import Papa from 'papaparse';
import { Country, SingleFileUploadProps, UploadableFiles } from "../../types";
import { setUploadError, setRecordsInFile } from "../../features/bulkEnrichment/bulkEnrichmentSlice";
import { validKeys, validRegex } from "../../utils/commonUtility";

export const SingleFileUpload: React.FC<SingleFileUploadProps> = ({
  setFilePresent,
  files,
  setFiles,
  isFilePresent,
}) => {
  const dispatch = useAppDispatch();
  const [validationErrors, setValidationErrors] = useState<String[]>([]);
  const [validationAlert, setValidationAlert] = useState<boolean>(false);
  const [modalState, setModalState] = useState(() => ({
    open: false,
    status: undefined,
  }));
  const { tenant_limit_reached } = useAppSelector((state) => state.bulkEnrichment);
  const closeModal = () => setModalState({ open: false, status: undefined });
  const isValidISO2CountryCode = function (
    field: string | number | boolean
  ): boolean {
    if (typeof field !== "string") {
      return false;
    }

    const countryCode = field;
    const country = countries["all"].find(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (country: Country) => country.alpha2.toLowerCase() === countryCode.toLowerCase());
    return country ? true : false;
  };
  const validateData = useCallback((parsedData: any[]): { type: string, errorMessage: string[] }[] => {
    const customerNumberSet = new Set<string>();
    const customerIdSet = new Set<string>();
    const errorCounts = {
      missingCustomerNumberAndId: 0,
      duplicateCustomerNumber: 0,
      duplicateCustomerId: 0,
      invalidCountryCode: 0,
      missingCompanyNameAndEmail: 0,
      invalidEmail: 0,
    };

    const errorsArray: { type: string, errorMessage: string[] }[] = [];
    dispatch(setRecordsInFile(parsedData.length))
    if (parsedData.length > 0) {
      const rowKeys = Object.keys(parsedData[0]);
      const invalidKeyExists = rowKeys.some((key) => !validKeys.includes(key));
      
      if (invalidKeyExists) {
        errorsArray.push({
          type: "invalidColumnName",
          errorMessage: ["Column name should be same as in provided template."]
        });
        return errorsArray;
      }
    }
    if (parsedData.length > 5000) {
      errorsArray.push({
        type: "numberOfColumns",
        errorMessage: ["File contains more than 5000 records."]
      });
      return errorsArray;
    }
    if (tenant_limit_reached) {
      errorsArray.push({
        type: "TenantLimitReached",
        errorMessage: ["Daily reach of tenant has completed,Please try again after 24Hrs."]
      });
      return errorsArray;
    }
    if (!parsedData || parsedData.length === 0) {
      errorsArray.push({
        type: "numberOfRows",
        errorMessage: ["There are no records in the file uploaded."]
      });
      return errorsArray;
    }
    parsedData.forEach((row) => {
      if (!row.CUSTOMER_NUMBER && !row.CUSTOMER_ID) {
        errorCounts.missingCustomerNumberAndId++;
      }
      if (row.CUSTOMER_NUMBER) {
        if (customerNumberSet.has(row.CUSTOMER_NUMBER)) {
          errorCounts.duplicateCustomerNumber++;
        } else {
          customerNumberSet.add(row.CUSTOMER_NUMBER);
        }
      }

      if (row.CUSTOMER_ID) {
        if (customerIdSet.has(row.CUSTOMER_ID)) {
          errorCounts.duplicateCustomerId++;
        } else {
          customerIdSet.add(row.CUSTOMER_ID);
        }
      }
      if (!row.COUNTRY_CODE || !isValidISO2CountryCode(row.COUNTRY_CODE)) {
        errorCounts.invalidCountryCode++;
      }
      if (!row.COMPANY_NAME && !row.EMAIL) {
        errorCounts.missingCompanyNameAndEmail++;
      }
      if (row.EMAIL && !row.EMAIL.match(validRegex)) {
        errorCounts.invalidEmail++;
      }
    });

    const errorConditions = [
      {
        condition: errorCounts.missingCustomerNumberAndId > 0,
        type: "missingCustomerNumberOrId",
        message: ` Some (${errorCounts.missingCustomerNumberAndId}) of the records don't have CusomerId and Customer Number Present.`
      },
      {
        condition: errorCounts.duplicateCustomerNumber > 0,
        type: "duplicateCustomerNumber",
        message: `Some (${errorCounts.duplicateCustomerNumber}) of the records have duplicate Customer Number.`
      },
      {
        condition: errorCounts.duplicateCustomerId > 0,
        type: "duplicateCustomerId",
        message: `Some (${errorCounts.duplicateCustomerId}) of the records have duplicate Customer ID.`
      },
      {
        condition: errorCounts.invalidCountryCode > 0,
        type: "invalidCountryCode",
        message: `Some (${errorCounts.invalidCountryCode}) of the records have invalid or missing Country Code.`
      },
      {
        condition: errorCounts.missingCompanyNameAndEmail > 0,
        type: "emailOrCompany",
        message: `Some (${errorCounts.missingCompanyNameAndEmail}) of the records have both Company Name and Email as null.`
      },
      {
        condition: errorCounts.invalidEmail > 0,
        type: "invalidEmail",
        message: `Some (${errorCounts.invalidEmail}) of the records have invalid EmailId.`
      },
    ];

    errorConditions.forEach(errorCondition => {
      if (errorCondition.condition) {
        errorsArray.push({
          type: errorCondition.type,
          errorMessage: [errorCondition.message]
        });
      }
    });

    return errorsArray;


  }, [dispatch, tenant_limit_reached])
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        setValidationAlert(false);
        dispatch(setUploadError(""));
        const mapAcceptedFiles = await Promise.all(
          acceptedFiles.map(async (file) => {
            return new Promise<UploadableFiles>((resolve, reject) => {
              Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                  const validationResponse = validateData(result.data);
                  if (Array.isArray(validationResponse)) {
                    const validationErrors = validationResponse;
                    if (validationErrors.length > 0) {
                      let allErrorMessages: string[] = [];
                      validationErrors.forEach(error => {
                          allErrorMessages = allErrorMessages.concat(error.errorMessage);
                      });
                      const error = validationErrors;
                      if (error) {
                        setValidationErrors(allErrorMessages);
                        setValidationAlert(true);
                        reject(new Error("Validation error"));
                      }
                    } else {
                      setValidationErrors([]);
                      resolve({
                        file: file,
                        errors: [],
                      });
                    }
                  } else {
                    console.error("Invalid validation response format:", validationResponse);
                    reject(new Error("Validation error"));
                  }
                },
                error: (error) => {
                  console.error("Error parsing CSV file:", error);
                  reject(error);
                }
              });
            });
          })
        );
        if (mapAcceptedFiles.length > 0) {
          setFiles(mapAcceptedFiles);
          setFilePresent(true);
          dispatch(setSnackBar(true));
        }
      } catch (error) {
        console.error("Error occurred during file processing:", error);
      }
    },
    [setFilePresent, setFiles, dispatch, validateData]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: formats,
    maxFiles: 1,
    multiple: false,
    disabled: files.length ? true : false,
  });
  const units = ["bytes", "KiB", "MiB"];

  function bytesFormat(x: string) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  return (
    <div>
      <Modal
        status={modalState.status}
        style={{ marginTop: "10vh" }}
        show={modalState.open}
        title={"File upload failed"}
        onRequestHide={closeModal}
        closeButton={true}
        footer={<Button onClick={closeModal}>Close</Button>}
      >
        <div>
          {"File upload failed due to following reasons"}
        </div>
        <ul>
          {validationErrors.map((error) => {
            return <li>{error}</li>;
          })}
        </ul>
      </Modal>
      {validationAlert && (
        <Snackbar
          delay={undefined}
          show={true}
          status={"danger"}
        >
          {"File not uploaded ! ! !"}
          <Button
            variant="anchor"
            onClick={() => {
              setModalState({ open: true, status: undefined });
            }}
            style={{ marginLeft: "1vw" }}
          >
            View Info
          </Button>
        </Snackbar>
      )}
      <div>
        <div {...getRootProps({ className: "file-uploader" })}>
          <input {...getInputProps()} data-testid="file-uploader-card" id="file-uploader-card"/>
          <div>
            {files.map((SingleFile, index) => (
              <Card key={index} className="accepted-file">
                <div className="accepted-file-container">
                  <div style={{ display: "flex" }}>
                    <img src={CSVIcon} alt="CSV icon" />
                    <div style={{ marginLeft: "20px" }}>
                      <div>{SingleFile.file.name}</div>
                      <div>{bytesFormat(SingleFile.file.size.toString())}</div>
                    </div>
                  </div>
                  <div>
                    <img
                      src={DeleteIcon}
                      alt="Delete File"
                      onClick={() => {
                        setFiles([]);
                        setFilePresent(false);
                        dispatch(setSnackBar(false));
                      }}
                      style={{
                        cursor: "pointer",
                        marginTop: "2vh",
                      }}
                    />
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div className="file-select-button">
            {isDragActive && !isFilePresent ? (
              <p>Drop the file here ...</p>
            ) : (
              !isFilePresent && (
                <div>
                  <Button variant="primary">Select File</Button>
                </div>
              )
            )}
          </div>
          {!isFilePresent && (
            <div className="drag-drop">
              <img
                src={UploadIcon}
                alt="Upload File"
                style={{ marginLeft: "3.5vw" }}
              />
              <div>Drag and drop or select file to upload</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
