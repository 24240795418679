import { Checkbox } from "@cimpress/react-components";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as DefaultSortIcon } from "../../assets/sort-default.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as AscendingSortIcon } from "../../assets/sort-ascending.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as DescendingSortIcon } from "../../assets/sort-descending.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LockIcon from "../../assets/lock.svg";
import "../../styles/table.css";
import "../../styles/editicons.css";
import { ToolTipComp } from "../Hocs/toolTip";

import {
	getViewData,
	setHeaderChecked,
	setSelectedRowIndexes,
	setSortColumn,
	setSortOrder,
} from "../../features/view/viewSlice";

import _ from "lodash";
import { notEditableColumns,PRINTDEAL_TABLES, IS_ENRICHED_COLUMN} from "../../config";
import React from "react";
import { AuthContext } from "../../context/authContext";
import { IFilter } from "../../types";

const getClassName = (columnName: string) => {
	const longHeaders = [
		"organization_id",
		"customer_id",
		"customerid",
		"customer_correlation_id",
		"website",
		"company_website",
		"ce_industry_l1",
		"ce_industry_l2",
	];
	const smallHeaders = [
		"sbi",
		"local_employee_count",
		"global_employee_count",
		"revenue_in_euros",
		"countrycode",
		"btw",
		"employees",
		"establishment",
		"house_number",
		"lei",
		"pand_id",
		"rsin",
		"employee_count",
		"revenue_usd",
		"year_of_establishment",
		"vat_number",
		"establishment_year",
		"date_of_establishment",
		"industry_confidence_score",
		"establishment_year_range",
		"employee_range",
		"web_pages_range",
		"count_of_linked_accounts",
		"linked_account_range",
		"number_of_web_pages",
		"customer_type",
		"number_of_employees",
		"revenue_in_usd",
		"confidence",
		"nace_code",
		"naics_code",
		"sdr_web_scrape",
		"sdr_inferred",
		"sdr_govt",
		"sdr_open_dataset",
		"sdr_artwork",
		"website_accuracy",
		"type",
		"sub_type",
		"updated_at",
		"case_number",
		"snowflake_business",
		"company_enriched",
	];
	const smallerHeaders = [
		"country_code",
		"sbi_2",
		"sbi_2_digit_code",
		"sbi_3",
		"sbi_4",
		"sbi_5",
		"rno",
		"country",
		"postcode",
		"zzp",
		"sbi_sector_code",
		"sic_code",
		"active",
		"fill_ratio",
	];
	const websiteColumns = ["website","company_website"]
	if (
		longHeaders.indexOf(columnName.toLowerCase()) !== -1 &&
		websiteColumns.indexOf(columnName.toLowerCase()) === -1
	) {
		return "long-header";
	} else if (websiteColumns.indexOf(columnName.toLowerCase()) !== -1) {
		return "table-head long-header";
	} else if (smallHeaders.indexOf(columnName.toLowerCase()) !== -1) {
		return "small-header";
	} else if (smallerHeaders.indexOf(columnName.toLowerCase()) !== -1) {
		return "smaller-header";
	} else {
		return "table-head";
	}
};

export function TableHeader(props: any) {
	const isHeaderChecked = useAppSelector((state) => state.view.headerChecked);
	const viewState = useAppSelector((state) => state.view);
	const { profile } = React.useContext(AuthContext);
	const email = profile?.email || "";

	const {
		unsavedFilters,
		pageSize,
		pageNumber,
		selectedView,
		selectedTable,
		visibleColumns,
		sortColumn,
		sortOrder,
		selectedHeaders,
		rows,
		quickFilters,
		filterOP,
	} = viewState;
	const view = selectedView || {
		viewId: "default",
		name: "All Columns Visible",
	};
	const dispatch = useAppDispatch();
	const onSortingColumn = (columnName: string) => {
		let order = "ASC";
		if (columnName === sortColumn) {
			order = sortOrder === "ASC" ? "DESC" : "ASC";
		}
		dispatch(setSortColumn(columnName));
		dispatch(setSortOrder(order));
		dispatch(
			getViewData({
				currentPage: pageNumber,
				size: pageSize,
				viewId: view.viewId,
				unsavedFilters,
				savedFilters: [],
				selectedTable: selectedTable,
				selectedColumns: selectedHeaders,
				visibleColumns: visibleColumns,
				sortingOrder: order,
				sortingColumn: columnName,
				userId: email,
				isSort: true,
				quickFilters,
				filterOp: filterOP,
			})
		);
	};
	let isEnriched_filter = unsavedFilters.some((filter: IFilter) => 
		filter.column === IS_ENRICHED_COLUMN &&
		filter.value.map(val => val.toLowerCase()).includes("true") &&
		(unsavedFilters.length > 1 ? filterOP === 'AND' : true)
	);	

	const show_multiselect = (selectedTable: string, isEnriched_filter: boolean) => {
		return !PRINTDEAL_TABLES.includes(selectedTable) || isEnriched_filter;
	}
		
 	const toggleMultipleSelect = () => {
		if (!isHeaderChecked) {
			dispatch(setSelectedRowIndexes(_.range(0, rows.length)));
		} else {
			dispatch(setSelectedRowIndexes([]));
		}
		dispatch(setHeaderChecked(!isHeaderChecked));
	};
	
	const { headerGroups } = props;
	const lockIcon = <img alt="" src={LockIcon} />;
	return (
		<thead>
			{headerGroups.map((headerGroup: any) => (
				<tr className="table-row" {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map((column: any, index: number) => {
						const editable = notEditableColumns.indexOf(column.id) === -1;
						const hidden = column.id === "ENRICHMENT_CORRELATION_ID";
						if (hidden) return <></>;
						return (
							<th {...column.getHeaderProps()} className={getClassName(column.id)}>
								<div
									{...column.getResizerProps()}
									className={`resizer ${column.isResizing ? "isResizing" : ""}`}
								/>

								{index === 0 && show_multiselect(selectedTable,isEnriched_filter) &&(
									<span className="mutliselect-checkbox">
										<Checkbox
											label={undefined}
											checked={isHeaderChecked}
											payload={undefined}
											onChange={toggleMultipleSelect}
											disabled={false}
										/>
									</span>
								)}
								
								<span className="header-content">{_.startCase(column.id)}</span>
								<span className="sort-icon">
									{sortOrder === "ASC" && sortColumn === column.id && (
										<AscendingSortIcon onClick={() => onSortingColumn(column.id)} />
									)}
									{sortOrder === "DESC" && sortColumn === column.id && (
										<DescendingSortIcon onClick={() => onSortingColumn(column.id)} />
									)}
									{sortColumn !== column.id && (
										<DefaultSortIcon onClick={() => onSortingColumn(column.id)} />
									)}
								</span>
								{!editable && (
										<span className="header-edit-icon">
											<img alt="" src={LockIcon} />
										</span>
									) &&
									ToolTipComp({
										children: lockIcon,
										message: "Immutable Field",
									})}
							</th>
						);
					})}
				</tr>
			))}
		</thead>
	);
}
