import { Spinner } from "@cimpress/react-components";
import { useState } from "react";
import {
  INTERNAL_VALIDATION_TABLES,
  linkColumns,
  notEditableColumns,
  overWritableColumns,
} from "../../config";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import "../../styles/table.css";
import { IStatus, Status } from "../../types";
import { Comments } from "../comments";
import { StatusMark } from "../labels/statusMark";
import { SelectCheckBox } from "./checkboxcomp";
import { EditIcons } from "./editIcons";
import { OverWriteComp } from "./overWrite";
import classNames from "classnames";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import {
  setDetailedViewTable,
  setbusinessFields,
  setcustomFields,
  setfirmographics,
  setinputFields,
  setisDetailedView,
  setlinkedCustomerIds,
  setDetailedViewRow,
  setMetaData,
  setDataProviderResponse,
  setEnrichedValue,
  setCheckedStateCorrect,
} from "../../features/view/viewSlice";
import {
  detailedViewDataProviders,
  detailedViewOverview,
} from "../../hooks/view-data";
import { evoPortalRoute } from "../../utils/commonUtility";
const getClassName = (columnName: string) => {
  const longHeaders = [
    "organization_id",
    "customer_id",
    "customerid",
    "customer_correlation_id",
    "website",
    "company_website",
    "ce_industry_l1",
    "ce_industry_l2",
  ];
  const smallHeaders = [
    "sbi",
    "local_employee_count",
    "global_employee_count",
    "revenue_in_euros",
    "countrycode",
    "btw",
    "employees",
    "establishment",
    "house_number",
    "lei",
    "pand_id",
    "rsin",
    "employee_count",
    "revenue_usd",
    "year_of_establishment",
    "vat_number",
    "establishment_year",
    "date_of_establishment",
    "industry_confidence_score",
    "establishment_year_range",
    "employee_range",
    "web_pages_range",
    "count_of_linked_accounts",
    "linked_account_range",
    "number_of_web_pages",
    "customer_type",
    "number_of_employees",
    "revenue_in_usd",
    "confidence",
    "nace_code",
    "naics_code",
    "sdr_web_scrape",
    "sdr_inferred",
    "sdr_govt",
    "sdr_open_dataset",
    "sdr_artwork",
    "website_accuracy",
    "type",
    "sub_type",
    "updated_at",
    "case_number",
    "snowflake_business",
    "company_enriched",
  ];
  const smallerHeaders = [
    "country_code",
    "sbi_2",
    "sbi_2_digit_code",
    "sbi_3",
    "sbi_4",
    "sbi_5",
    "rno",
    "country",
    "postcode",
    "zzp",
    "sbi_sector_code",
    "sic_code",
    "active",
    "fill_ratio",
  ];

  const websiteColumns = ["website", "company_website"];

  if (
    longHeaders.indexOf(columnName.toLowerCase()) !== -1 &&
    websiteColumns.indexOf(columnName.toLowerCase()) === -1
  )
    return "table-cell long-cell";
  else if (websiteColumns.indexOf(columnName.toLowerCase()) !== -1) {
    return "table-cell long-cell";
  } else if (smallHeaders.indexOf(columnName.toLowerCase()) !== -1) {
    return "table-cell small-cell";
  } else if (smallerHeaders.indexOf(columnName.toLowerCase()) !== -1) {
    return "table-cell_alt smaller-cell";
  }

  return "table-cell";
};
const getIdentifier = () => {
  return "ENRICHMENT_CORRELATION_ID";
};

const getColumStatus = (
  statusArr: IStatus[][],
  columnName: any,
  rowIndex: number
) => {
  const row = statusArr[rowIndex];

  let status = "notVerified";
  let overwritten = "false";
  let commented = "false";
  for (let colIndex = 0; colIndex < row.length; colIndex++) {
    let eachCol = row[colIndex];
    if (eachCol["cell"] === columnName) {
      status = eachCol["status"];
      overwritten = eachCol["overwritten"];
      commented = eachCol["commented"];
      break;
    }
  }
  return { status, overwritten, commented };
};

export function TableCell(props: {
  cell: any;
  cellIndex: number;
  row: any;
  rowIndex: number;
}) {
  const { cell, cellIndex, row, rowIndex } = props;
  const {
    selectedRowsIndexes: selectedIndexes,
    selectedHeaders,
    loadingEmployeeRows,
    loadingIndustryRows,
    loadingWebsiteRows,
    allLoading,
    statusArr,
    selectedTable,
  } = useAppSelector((state) => state.view);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const isEditable = notEditableColumns.indexOf(cell.column.id) === -1;
  const randomKey = `${cell.value}-${cellIndex.toString()}`;
  const isLink = linkColumns.indexOf(cell.column.id) !== -1;
  const validate_boolean = (value: any) => {
    if (typeof value === "boolean") {
      return value ? "true" : "false";
    } else {
      return value && value !== "NULL" && value !== "None" ? value : "";
    }
  };
  const content = validate_boolean(cell.value);
  const isOverWritable =
    overWritableColumns.indexOf(cell.column.id) !== -1 &&
    INTERNAL_VALIDATION_TABLES.indexOf(selectedTable) === -1;
  const identifier = getIdentifier();
  const identifierId = row.values[identifier];
  const stickyColumns: string[] = ["COMPANY_NAME", "CUSTOMER_COMPANY_NAME"];
  const isSticky = stickyColumns.indexOf(cell.column.id) !== -1;

  const websiteColumns = ["website", "company_website"];
  const getIsEnriched = () => {
    const isEnrichedValue =
      row.values["IS_ENRICHED"] === false && cell.column.id !== "SUB_SEGMENT";
    return !isEnrichedValue;
  };
  const getId = (columns: string[]) => {
    if (
      row.values["ENRICHMENT_CORRELATION_ID"] === row.values["CUSTOMER_NUMBER"]
    )
      return "CUSTOMER_NUMBER";
    else if (
      row.values["ENRICHMENT_CORRELATION_ID"] === row.values["CUSTOMERID"]
    ) {
      return "CUSTOMERID";
    } else if (
      row.values["ENRICHMENT_CORRELATION_ID"] === row.values["CUSTOMER_ID"]
    )
      return "CUSTOMER_ID";
    else return "CUSTOMER_CORRELATION_ID";
  };

  const customerIdentifer = getId(selectedHeaders);
  const isCid = cell.column.id === customerIdentifer;
  const customerIdentifierId = row.values[customerIdentifer];

  const handleIdentifierClick = () => {
    const encodedTable = Buffer.from(selectedTable).toString("base64");
    const newSearchUrl =
      `/${evoPortalRoute}/detailedView?viewTab=overview&tableName=` +
      encodedTable +
      "&customerIdentifier=" +
      customerIdentifer +
      "&enrichmentCorrelationId=" +
      customerIdentifierId;
    navigate(newSearchUrl);
    dispatch(setisDetailedView(true));
    dispatch(setDetailedViewTable(selectedTable));
    detailedViewOverview(
      customerIdentifierId,
      selectedTable.toLowerCase()
    ).then((response) => {
      dispatch(setinputFields(response["input_fields"]));
      dispatch(setfirmographics(response["firmographics"]));
      dispatch(setcustomFields(response["misc_fields"]));
      dispatch(setlinkedCustomerIds(response["linked_customer_ids"]));
      dispatch(setbusinessFields(response["business_fields"]));
      dispatch(setDetailedViewRow(response["record"]));
      dispatch(setMetaData(response["metadata"]));
    });
    detailedViewDataProviders(
      customerIdentifierId,
      selectedTable.toLowerCase()
    ).then((response) => {
      if (response.hasOwnProperty("all_providers")) {
        dispatch(
          setCheckedStateCorrect(
            new Array(response.all_providers.length).fill(false)
          )
        );
      }
      dispatch(setDataProviderResponse(response));
      dispatch(setEnrichedValue(response["enriched_values"]));
    });
  };
  const linkUi = (
    <div className="link-content">
      <a
        style={{ color: "#0088a9", textDecoration: "none" }}
        href={content}
        rel="noreferrer"
        target="_blank"
      >
        {content}
      </a>
    </div>
  );
  const linkCid = (
    <>
      {!getIsEnriched() && <span>{content}</span>}
      {getIsEnriched() && (
        <div className="link-content" id={`link-cid-wrapper-${cellIndex}`}>
          <div
            style={{ color: "#0088a9", textDecoration: "none" }}
            onClick={() => {
              handleIdentifierClick();
            }}
            rel="noreferrer"
            id={`link-cid-inner-${cellIndex}`}
          >
            <span id={`link-cid-${cellIndex}`}>{content}</span>
          </div>
        </div>
      )}
    </>
  );
  const className = classNames({
    "long-cell-content":
      getClassName(cell.column.id) === "table-cell long-cell",
    "cell-content": getClassName(cell.column.id) === "table-cell",
    "small-cell-content":
      getClassName(cell.column.id) === "table-cell small-cell" ||
      getClassName(cell.column.id) === "table-cell_alt smaller-cell",
  });

  const staticUi = (
    <div className={className}>
      <span>{content}</span>
    </div>
  );
  const getLoadingStatus = () => {
    if (
      websiteColumns.indexOf(cell.column.id.toLowerCase()) !== -1 &&
      rowIndex in loadingWebsiteRows
    )
      return true;
    if (
      cell.column.id.toLowerCase() === "ce_industry_l1" &&
      rowIndex in loadingIndustryRows
    )
      return true;
    if (
      cell.column.id.toLowerCase().indexOf("employees") !== -1 &&
      rowIndex in loadingEmployeeRows
    )
      return true;
    return false;
  };
  const {
    status: columnStatus,
    overwritten,
    commented,
  } = getColumStatus(statusArr, cell.column.id, rowIndex);
  const hidden = cell.column.id === getIdentifier();
  if (hidden) return <></>;
  return (
    <td
      {...cell.getCellProps()}
      className={
        isSticky
          ? `${getClassName(cell.column.id)} hoverable-element`
          : getClassName(cell.column.id)
      }
      key={randomKey}
    >
      {(loading || getLoadingStatus()) && !allLoading && (
        <>
          <div className="cell-overlay"></div>
          <Spinner size="medium" className="cell-spinner" />
        </>
      )}
      {cellIndex === 0 && (
        <SelectCheckBox
          isEnriched={getIsEnriched()}
          rowIndex={rowIndex}
          indexesArr={selectedIndexes}
        />
      )}

      {(!isLink || (!_.isNull(content) && content.toLowerCase() === "na")) &&
        !isCid &&
        staticUi}
      {isLink && !_.isNull(content) && content.toLowerCase() !== "na" && linkUi}
      {isCid && linkCid}
      {getIsEnriched() && isEditable && !(loading || getLoadingStatus()) && (
        <>
          <EditIcons
            setLoading={setLoading}
            rowIndexes={[rowIndex]}
            columnName={cell.column.id}
            columnStatus={columnStatus}
          ></EditIcons>
          <Comments
            field={cell.column.id}
            id={identifierId}
            commented={commented}
            rowIndex={[rowIndex]}
          />
        </>
      )}
      {getIsEnriched() &&
        isOverWritable &&
        !(loading || getLoadingStatus()) && (
          <OverWriteComp
            rowIndex={[rowIndex]}
            setLoading={setLoading}
            field={cell.column.id}
            value={cell.value}
            identifierId={identifierId}
            identifier={identifier}
            overwritten={overwritten}
          ></OverWriteComp>
        )}
      {getIsEnriched() &&
        columnStatus !== Status.NotVerified &&
        !(loading || getLoadingStatus()) && (
          <StatusMark status={columnStatus} />
        )}
    </td>
  );
}
